// src/App.js
import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    // Redirect to a different website
    window.location.href = 'https://vaibhav-life.framer.website';
  }, []);

  return (
    <div className="App">
      <p>Redirecting...</p>
    </div>
  );
}

export default App;
